import React from 'react';  
import {FooterLanding, PhoneContactLanding} from '../Component/Template';
  
const MainLayout = ({children, ...rest}) => {  
  return (  
    <>
    {children}
    <FooterLanding /> 
    <PhoneContactLanding/>
    </>
   
  )  
}  
export default MainLayout;  
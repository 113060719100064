import React from 'react';  
import { Contacts } from '../Component/Main';
import { Footer, Header,Nav ,Popup,BlockContact,Chat} from '../Component/Template';
  
const MainLayout = ({children, ...rest}) => {  
  return (  
    <>
    <Header />
    <Nav />
    {children}
    <Footer />  
    <Popup/>
    <BlockContact/>
    
    
    
   <Chat/>
    </>
   
  )  
}  
export default MainLayout;  
import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useHistory } from "react-router-dom";

import { useInput } from "../Hooks";
import { mainAction } from "../Redux/Actions";
import { Alertwarning, Alertsuccess } from "../Utils";
import { APIKey } from "../Services";
import I18n from '../Language';
import { SelectCity } from ".";
const ContactFormComp = React.forwardRef(() => {
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);
  const location = useLocation();

  const [Name, bindName, setName] = useInput("");
  const [Email, bindEmail, setEmail] = useInput("");
  const [Phone, bindPhone, setPhone] = useInput("");
  const [Content, bindContent, setContent] = useInput("");
  const [LadingCode, bindLadingCode, setLadingCode] = useInput("");
  const [Type, setType] = useState(0);
  const [TypeContact, setTypeContact] = useState("");

  const [TypeComplain, setTypeComplain] = useState(1);

  const [Url, setUrl] = useState("https://netco.com.vn");
  const history = useHistory()
  useEffect(() => {
    let domain = 'https://netco.com.vn'
    setUrl(domain + location?.pathname)
  }, [location]);
  /* const [AreaId, setAreaId] = useState(0);
  const [Area, setArea] = useState(""); */

  const [CityId, setCityId] = useState(0);
  const [CityName, setCityName] = useState("");
  const [LabelButton, setLabelButton] = useState(I18n.t("ContactForm.Action"));

  const NameRef = useRef();
  const EmailRef = useRef();
  const PhoneRef = useRef();
  const ContentRef = useRef();
  const LadingCodeRef = useRef();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const validateEmail = (email) => {
    const emailRegex = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
    return emailRegex.test(email);
  };

  const validatePhone = (phone) => {
    const phoneRegex = /^(0|\+84)(\s|\.)?((3[2-9])|(5[689])|(7[06-9])|(8[1-689])|(9[0-46-9]))(\d)(\s|\.)?(\d{3})(\s|\.)?(\d{3})$/;
    return phoneRegex.test(phone);
  };

  const onAction = async () => {
    if (!validateEmail(Email)) {
      Alertwarning(I18n.t("ContactForm.EmailInvalidate"));
      EmailRef.current.focus();
      return;
    }
    if (!validatePhone(Phone)) {
      Alertwarning(I18n.t("ContactForm.PhoneInvalidate"));
      PhoneRef.current.focus();
      return;
    }
    if (Name === "") {
      Alertwarning(I18n.t("ContactForm.RequiredName"));
      NameRef.current.focus();
      return;
    } else if (Email === "") {
      Alertwarning(I18n.t("ContactForm.RequiredEmail"));
      EmailRef.current.focus();
      return;
    } else if (Phone === "") {
      Alertwarning(I18n.t("ContactForm.RequiredPhone"));
      PhoneRef.current.focus();
      return;
    } else if (Content === "") {
      Alertwarning(I18n.t("ContactForm.RequiredContent"));
      ContentRef.current.focus();
      return;
    } else if (Type === 0) {
      Alertwarning(I18n.t("ContactForm.RequiredType"));
      return;
    }
    else if (CityId === 0) {
      Alertwarning(I18n.t("ContactForm.RequiredArea"));
      return;
    }
    else {
      setDisabled(true);
      if (TypeContact === "CSKH") { //Nếu khách chọn khiếu nại dịch vụ
        let params = {
          CustomerId: 0,
          Name: Name,
          Phone: Phone,
          LadingCode: LadingCode,
          Type: parseInt(TypeComplain),
          Content: Content,
          SendFrom: "TỪ NETCO WEBSITE",
          CityId: CityId,
          CityName: CityName,
          TypeReceipt: 1,
        };
        const pr = {
          ApiKey: APIKey,
          Json: JSON.stringify(params),
          func: "APIC_spCustomerComplain_JsonAuto",
          IsLoading: true
        };
        try {
          const list = await mainAction.API_spCallServerSystem(pr, dispatch);
          if (list.length > 0) {
            history.push('/thankyou')
            Alertsuccess(I18n.t("ContactForm.Success"));
            onReset();
            onSendNofity("ComplainCreate");
          }
          setDisabled(false);
        } catch (err) {
          setDisabled(false);
        }
      }
      else { // Nếu khách chọn tư vấn/báo giá
        const pr = {
          Json: '{"Url":"' + Url + '","Name":"' + Name + '","Email":"' + Email + '","Phone":"' + Phone + '","ContactContent":"' + Content + '", "Type":"' + Type + '", "TypeName":"' + TypeContact + '", "AreaId":"' + CityId + '", "AreaName": "' + CityName + '","Status":1, "StatusName":"Chưa xử lý"}',
          func: "CRM_spCustomerContactRequest_Save",
        };
        try {
          const list = await mainAction.API_spCallServerSystem(pr, dispatch);
          if (list.length > 0) {
            history.push('/thankyou')
            Alertsuccess(I18n.t("ContactForm.Success"));
            onReset();
            onSendNofity("ShippingConsulting");
          }
          setDisabled(false);
        } catch (err) {
          setDisabled(false);
        }
      }
    }
  }

  const onReset = () => {
    setName("");
    setPhone("");
    setEmail("");
    setContent("");
  }

  const onSendNofity = async (functionsend) => {
    const NotifiParam = {
      Json: JSON.stringify({
        CustomerId: 0,
        FuncSend: functionsend,
        SendFrom: "WEBSITE NETCO.COM.VN",
        CustomerProvince: CityId,
        JsonData: [
          {
            Name: Name,
            Phone: Phone,
            Content: Content,
          },
        ],
      }),
      func: "APIC_spSendNotification",
      API_key: APIKey,
    };
    const resultNotify = await mainAction.API_spCallServerSystem(
      NotifiParam,
      dispatch
    );
    if (resultNotify.length > 0) return;
  }

  const onSelectCity = (item) => {
    setCityId(item.value);
    setCityName(item.label);
  }

  return (
    <div className="form" aria-autocomplete="off">
      <div className="form-group">
        <label className="special-label">{I18n.t("ContactPage.InputName")} <span className="text-red">(*)</span></label>
        <input className="form-control" type="text" ref={NameRef} value={Name}  {...bindName} maxLength="50" placeholder={I18n.t("ContactForm.InputName")} required />
      </div>
      <div className="form-group">
        <label className="special-label">{I18n.t("ContactPage.InputEmail")} <span className="text-red">(*)</span></label>
        <input className="form-control" type="text" ref={EmailRef} value={Email} onChange={e => handleEmailChange(e)} {...bindEmail} maxLength="50" placeholder={I18n.t("ContactForm.InputEmail")} required />
      </div>
      <div className="form-group">
        <label className="special-label">{I18n.t("ContactPage.InputPhone")} <span className="text-red">(*)</span></label>
        <input className="form-control" type="text" ref={PhoneRef} value={Phone} onChange={e => handlePhoneChange(e)} {...bindPhone} maxLength="15" placeholder={I18n.t("ContactForm.InputPhone")} required />
      </div>
      <div className="form-group">
        <label>{I18n.t("ContactForm.SelectYourArea")} <span className="text-red">(*)</span></label>
        <div style={{ color: "#19191d" }}>
          <SelectCity key={"CityTo"}
            className={"form-control"}
            Disabled={disabled}
            onActive={CityId}
            onSelected={(item) => onSelectCity(item)}
          />
        </div>
      </div>
      <div className="form-group">
        <label>{I18n.t("ContactForm.WhatDoYouNeed")} <span className="text-red">(*)</span></label>
        <div className="row">
          <div className="col-md-6">
            <label className="form-check" onClick={(e) => { setTypeContact("TVDV"); setType(4); setLabelButton(I18n.t("ContactForm.SignUpForConsultation")) }}>
              {I18n.t("ContactForm.ShippingConsulting")}
              <input type="radio" name="radio" />
              <span className="checkmark"></span>
            </label>
          </div>
          {/* <div className="col-md-6">
            <label className="form-check" onClick={(e) => { setTypeContact("CSKH"); setType(3); setLabelButton(I18n.t("ContactForm.Action")) }}>
              {I18n.t("ContactForm.CustomerCare")}
              <input type="radio" name="radio" />
              <span className="checkmark"></span>
            </label>
          </div> */}
        </div>
      </div>
      {Type === 3 && (<>
        <div className="form-group">
          <label>{I18n.t("Complain.InputCode")}</label>
          <input className="form-control" type="text" ref={LadingCodeRef} value={LadingCode} {...bindLadingCode} maxLength="50" placeholder={I18n.t("Complain.InputCode")} />
        </div>
        <div className="form-group">
          <label>{I18n.t("Complain.InputType")} <span className="text-red">(*)</span></label>
          <select className="form-control" value={Type} onChange={(e) => setType(e.target.value)}>
            <option value="1">{I18n.t("Complain.ComplainService")}</option>
            <option value="2">{I18n.t("Complain.ComplainPrice")}</option>
            <option value="3">{I18n.t("Complain.ComplainCod")}</option>
          </select>
        </div>
      </>)}
      <div className="form-group">
        <label className="special-label">{I18n.t("ContactPage.InputContent")} <span className="text-red">(*)</span></label>
        <textarea className="form-control" rows="3" ref={ContentRef} value={Content} {...bindContent} placeholder={I18n.t("ContactForm.InputContent")} required></textarea>
      </div>
      <div className="form-group text-right mb20" style={{ display: 'flex', justifyContent: "center", position: 'relative', top: "15px" }}>
        <button type="text" className="btn btn-success pd-30" style={{ paddingLeft: '20px', paddingRight: '20px' }} disabled={disabled} onClick={(e) => onAction()}>{LabelButton} </button>
      </div>
    </div>
  );
});

export const ContactForm = React.memo(ContactFormComp);

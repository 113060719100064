import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { mainAction } from "../Redux/Actions";
import Select from 'react-select';
import { APIKey } from "../Services";
// import { SelectTopic } from "../../Common";

const SelectWeightComp = React.forwardRef(({
    onSelected = () => { },
    value = 0,
    IsLoad = -1,
    title='kilogram',
    Items = { value: 1, label: "kilogram" }
    
},ref ) => {
    const dispatch = useDispatch();
    const [data, setData] = useState([
        { value: 1, label: "kilogram" },
        { value: 2, label: "gram" },
        { value: 3, label: "cbm(khối)" },
        { value: 4, label: "tấn" }
    ])
    const [valueS, setValueS] = useState(Items);
   
    const onSelecteItem = (item) => {

        onSelected(item)
        setValueS(item);
    }
  
    useEffect(() => {
        setValueS(Items);
    }, [Items])

    useEffect(() => {
        if (data.value !== 0) {
            const val = [...data].filter(p => p.value === value);
            if (val.length > 0)
                setValueS(val[0]);
        }

    }, [data.value])
    
    return (
        <Select className="SelectMeno"
            id="SelectMeno"
            value={valueS}
            onChange={onSelecteItem}
            options={data}
            ref={ref}
                     
        />
    )
}
);

export const SelectWeight = React.memo(SelectWeightComp)
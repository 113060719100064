import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useHistory } from "react-router-dom";
import ReactHtml from 'raw-html-react';
import MetaTags from 'react-meta-tags';
import MainLayout from "../../Layout/MainLayout";
import { APIKey, FILE_URL, CUSTOMER_CREATE_LADING_URL } from "../../Services/Api";
import { mainAction } from "../../Redux/Actions";
import { ScrollTop } from "../../Utils";
import I18n from '../../Language';

export const ServiceDetail = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();

    const [Info, setInfo] = useState({});
    const [List, setList] = useState([]);
    const [ListChild, setListChild] = useState([]);

    useEffect(() => {
        const pr = new URLSearchParams(location.search);
        if (pr.get("title")!=="") {
            onGetData(pr.get("title"));
        }
        else
            history.push('/da-dang-dich-vu');
    }, [location]);

    const onGetData = async (title) => {
        let keyLang = localStorage.getItem("keyLang");
        const params = {
            Json: '{"Type":"Service","Enabled":1,"Lang":"' + keyLang + '"}',
            func: "WS_spGetInformation",
        };

        try {
            const list = await mainAction.CallApiWs(params, dispatch);
            let info = list.find(p => p.Url === title);
            setInfo(info);
            setList(list.filter(p => p.Url !== title && p.Keys === info.Keys && (info.ParentId === 0 || (info.ParentId !== 0 && p.ParentId === info.ParentId))));
            setListChild(list.filter(p => p.ParentId === info.Id));
            ScrollTop();
        } catch (err) {
        }
    };

    const HtmlChilds = (
        <ul className="mt20 service-child">
            {
                ListChild.map((item, index) => {
                    return (
                        <li key={item.Id + "SERVICE"}>
                            <div className="box-item">
                                <Link title={item.Title} to={"/dich-vu?title=" + item.Url}>
                                    <img alt={item.Title} className="img-normal" src={FILE_URL + item.ImgThumb} />
                                    <img alt={item.Title} className="img-hover" src={FILE_URL + item.ImgFull} />
                                    <div className="text">{item.Title}</div>
                                </Link>
                            </div>
                        </li>
                    )
                })
            }
        </ul>
    )

    const SEO = (
        <MetaTags>
            <title>{Info?.Title}</title>
            <meta name="description" content={Info?.ShortDesc} />
            <meta property="og:image" content={Info?.ImgThumb} />
            <meta property="og:title" content={Info?.Title} />
            <meta property="og:description" content={Info?.ShortDesc} />
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={window.location.href} />
            <meta property="og:site_name" content={Info?.Title} />
        </MetaTags>
    )

    return (
        <MainLayout>
        <section className="content">
            {SEO}
            <div className="container">
                <div className="sidemap">
                    <span><Link to="/">{I18n.t("TopMenu.Home")}</Link></span>
                    <span><i className="fa fa-angle-right"></i></span>
                    <span><Link to="/da-dang-dich-vu">{I18n.t("TopMenu.Service")}</Link></span>
                    <span><i className="fa fa-angle-right"></i></span>
                    <span>{Info?.Title}</span>
                </div>
                <div className="row">
                    <div className="col-md-8 text-justify box-service mt0 mb10">
                        <h1 className="h3 mb10">{Info?.Title}</h1>
                        <div className="">{Info?.ShortDesc}</div>
                        {ListChild.length > 0 ? HtmlChilds : null}
                        <ReactHtml html={Info?.LongDesc?.replaceAll("src=\"/image/", "src=\"" + FILE_URL + "image/")?.replaceAll("src=\"/Image/", "src=\"" + FILE_URL + "image/")} componentMap={{ ServiceDetail }} />
                    </div>
                    <div className="col-md-4 sidebar-right">
                        <h5 className="mt20 mb20 bold">{I18n.t("Sidebar.AnotherServices")}</h5>
                        {
                            List.map((item, index) => {
                                return (
                                    <Link to={"dich-vu?title=" + item.Url} title={item.Title} key={"another" + index}>
                                        <div className="box-li">
                                            <span>
                                                <img className="img-thumb" alt={item.Title} src={FILE_URL + item.ImgThumb} />
                                                <img className="img-hover" alt={item.Title} src={FILE_URL + item.ImgFull} />
                                            </span>
                                            <span className="mt5">{item.Title}</span>
                                        </div>
                                    </Link>
                                )
                            })
                        }
                        <div className="col-md-12 box-share mb10">
                            <div className="text-lg-bolder mb10 mt20">
                                {I18n.t("News.ShareToSocial")}
                            </div>
                            <a target="_blank" rel="noreferrer" href={"https://www.facebook.com/sharer/sharer.php?u=" + window.location.href} onClick="javascript:window.open(this.href,'', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');return false;">
                                <img src="/assets/img/fb-square.png" alt="Share on Facebook" />
                            </a>
                            <a target="_blank" rel="noreferrer" href={"https://plus.google.com/share?url=" + window.location.href} onClick="javascript:window.open(this.href,'', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');return false;">
                                <img src="/assets/img/gg-square.png" alt="Share on Google+" />
                            </a>
                            <a target="_blank" rel="noreferrer" href={"https://twitter.com/intent/tweet?original_referer=" + window.location.href + "%2F&amp;source=tweetbutton&amp;text=" + Info.Title + "&amp;url=" + window.location.href + "%2F&amp;via="} onclick="javascript:window.open(this.href,'', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');return false;">
                                <img src="/assets/img/tw-square.png" alt="Share on Twitter" />
                            </a>
                        </div>
                        <div className="col-md-12">
                            <a target="_blank" rel="noreferrer" href={CUSTOMER_CREATE_LADING_URL} className="btn btn-success w-100 white">{I18n.t("Banner.CreateLading")}</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </MainLayout>
    )
}
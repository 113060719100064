import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Cookies from 'universal-cookie';

import { LANE } from "../../Enum";
import { APIKey, FILE_URL } from "../../Services/Api";
import { mainAction } from "../../Redux/Actions";
import I18n from '../../Language';
import { getData, setData } from '../../Utils/Storage';
import { EncodeString } from "../../Utils";

export const Header = () => {
  const dispatch = useDispatch();

  const [Logo, setLogo] = useState({ ImgThumb: "Image/ckfinder/images/assets/logo.png" });

  const [LangName, setLangName] = useState(I18n.t("Header.Vietnamese"));
  const [LangIcon, setLangIcon] = useState("/assets/img/vn.png");

  useEffect(() => {
    //#region Đa ngôn ngữ hệ thống
    initialLanguage();
    //#endregion

    const cookies = new Cookies();
    cookies.set('fdfsfss', 'Pacman', { path: '/' });
    cookies.set('myCat', EncodeString(new Date() + "fdfwfgchtersdfvchersdzcxc cdfsesfdvxc   fdgsfvcxvzc "), { path: '/' });
    cookies.set("locale","vi-VN", { path: '/' })
    cookies.set("cors",EncodeString("fsdfdgeasdvjyhg"+new Date() + "fdfwfgcsdasdahtersadadddadsfdfvchersdzcxc cdfsesfdvxc   fdgsfvcxvzc "))
    onGetData();
  }, []);

  const onGetData = async () => {
    const params = {
      Json: '[{"Type":"Logo"}]',
      func: "WS_spGetInformation",
    };

    try {
      const list = await mainAction.CallApiWs(params, dispatch);
      setLogo(list[0]);
    } catch (err) {
    }
  };

  //#region đa ngôn ngữ hệ thống
  const changeLanguage = async (keylang) => {
    let lang = await getData(LANE);

    let params = {
      language: keylang,
      Type: 1
    }
    const language = await mainAction.changeLanguage(params, dispatch);
    await setData(LANE, JSON.stringify(language));
    //#region đa ngôn ngữ leftmenu
    localStorage.setItem("keyLang", keylang);
    window.location.reload();
    //#endregion


  }

  const initialLanguage = () => {
    dispatch(mainAction.checkLanguage(null))
    const keyLang = localStorage.getItem("keyLang");
    if (keyLang !== 'EN' && keyLang !== 'en') {
      setLangName("Tiếng Việt");
      setLangIcon("/assets/img/vn.png");
      localStorage.setItem("keyLang", "VN");
    } else {
      setLangName("English");
      setLangIcon("/assets/img/en.png");
    }
  }
  //#endregion

  return (
    <header>
      <div className="container">
        <div className="row">
          <div className="col-md-2 col-sm-6 logo">
            <Link title="NETCO POST" to="/"><img src={FILE_URL + Logo.ImgThumb} alt="NETCO POST" /></Link>
          </div>
          <div className="col-md-6 col-sm-6 title-info hide-xs">
            <ul>
              <li><span>{I18n.t('Header.WorkTime')}: <span className="text-green">{Logo.ImgFull}</span></span></li>
              <li className="hide-xs"> | </li>
              <li><span>{I18n.t('Header.Hotline')}: <span className="text-green">{Logo.ShortDesc} - {Logo.LongDesc}</span></span></li>
            </ul>
          </div>
          <div className="col-md-4 col-sm-12 title-select hide-xs">
            <ul>
              <li><a title="Đăng nhập" href="https://customer.netco.com.vn" className="btn btn-login"><i className="fa fa-user-circle"></i>{I18n.t('Header.Login')}</a></li>
              <li><a title="Đăng ký" href="https://customer.netco.com.vn/register" className="btn btn-register">{/* <i className="fa fa-user-plus"></i> */} {I18n.t('Header.Register')}</a></li>
              <li className="pd10 choose-lang">
                <a href="#" className="dropdown" title={LangName} data-toggle="dropdown">
                  {LangName} <img src={LangIcon} width="30" height="20" alt={LangName} />
                </a>
                <ul className="dropdown-menu">
                  <li><a onClick={e => changeLanguage("vn")}>{I18n.t("Header.Vietnamese")}  <img src="/assets/img/vn.png" width="30" height="20" alt="NETCO POST" /></a></li>
                  <li><a onClick={e => changeLanguage("en")}>{I18n.t("Header.English")}  <img src="/assets/img/en.png" width="30" height="20" alt="NETCO POST" /></a></li>
                </ul>
                {/* <span>Tiếng Anh <img src="/assets/img/en.png" width="30" height="20" /></span> */}
              </li>
            </ul>
          </div>
          <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#mainNav">
            <img src="/assets/img/icon24/menu-mb.png" />
          </button>
        </div>
      </div>
    </header>
  );
};

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { APIKey, FILE_URL } from "../../Services/Api";
import { mainAction } from "../../Redux/Actions";

export const Popup = () => {
  const dispatch = useDispatch();

  const [Info, setInfo] = useState([]);

  useEffect(() => {
    onGetData();
  }, []);
  const [PopupHtml, setPopupHtml] = useState(null);
  const onGetData = async () => {
    const params = {
      Json: '[{"Type":"Popup"}]',
      func: "WS_spGetInformation",
    };

    try {
      const list = await mainAction.CallApiWs(params, dispatch);
      setPopupHtml(
        list.filter(p => p.Enabled === 1).map((item, index) => {
          return (
            <a href={item.Url} target="_blank" rel="noreferrer">
              <img style={{ maxWidth: "100%" }} alt={item.Title} src={FILE_URL + item.ImgFull} />
            </a>
          )
        })
      );
    } catch (err) {
    }
  };

  return (
    <>
      <div className="modal fade" id="popupModal" tabIndex="-1" role="dialog" aria-labelledby="popupModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-body text-center">
              {PopupHtml}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
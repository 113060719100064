import React from "react";
import { Link } from "react-router-dom";
import { MapView, ContactForm } from "../../Common";
import I18n from '../../Language';
import MainLayout from "../../Layout/MainLayout";
export const Contacts = () => {

    return (
        <MainLayout>
        <section className="content">
            <div className="container">
                <div className="sidemap">
                    <span><Link to="/">{I18n.t("TopMenu.Home")}</Link></span>
                    <span><i className="fa fa-angle-right"></i></span>
                    <span>{I18n.t("TopMenu.ContactUs")}</span>
                </div>
                <div className="row mt10">
                    <div className="col-md-12">
                        <h3>{I18n.t('ContactPage.Title')}</h3>
                    </div>
                    <div className="col-md-6 form-pd">
                        <ContactForm key="contact-page" />
                    </div>
                    <div className="col-md-6">
                        <MapView key="afghh" />
                    </div>
                </div>
            </div>
        </section >
        </MainLayout>
    )
}
import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

import { useInput } from "../../Hooks";
import { mainAction } from "../../Redux/Actions";
import { Alertsuccess, Alertwarning, DecodeString, EncodeString, FormatDateJson, getData, setData ,Alerterror} from "../../Utils";
import { CHAT_LIST_USER, CHAT_USERS, CHAT_GROUPS, CHAT_USERS_LIST } from "../../Services";
import I18n from '../../Language';
import { Notification } from "../../Utils";
import { PriceTracking,ChatBotClone } from "../Main";
import { ChatBot, SelectGender, SelectCS, ChatbotClone } from "../../Common";
import { Alert } from "reactstrap";
import { useLocation } from "react-router";

// import {ChatBot} from '../../Common'

export const Chat = () => {
    
    const dispatch = useDispatch();
    const [disabled, setDisabled] = useState(false);

    const [Name, bindName, setName] = useInput("");
    const [Email, bindEmail, setEmail] = useInput("");
    const [Phone, bindPhone, setPhone] = useInput("");
    const [TypeContact, setTypeContact] = useState("");
    const [Area, setArea] = useState("");
    const [Address, bindAddress, setAddress] = useInput("");

    const NameRef = useRef();
    const EmailRef = useRef();
    const PhoneRef = useRef();
    const AddressRef = useRef();
    const [GenderId, setGenderId] = useState(0)
    const [Gender, setGender] = useState('')
    const [CSId, setCSId] = useState(0)
    const [CSName, setCSName] = useState("")
    const [Message, bindMessage, setMessage] = useInput("");
    const MessageRef = useRef();

    const [ChatBotInfo, setChatBotInfo] = useState(localStorage.getItem("ChatbotInformation"));
    const [ChatBotInfoGroup, setChatBotInfoGroup] = useState(localStorage.getItem("ChatbotInformationGroup"));

    const [Data, setData] = useState([]);

    const [ChatInfo, setChatInfo] = useState(localStorage.getItem("ChatInformation"));
    const [ChatInfoGroup, setChatInfoGroup] = useState(localStorage.getItem("ChatInformationGroup"));
    const [ChatTemp, setChatTemp] = useState([]);

    const [CurrentDate, setCurrentDate] = useState(new Date());
    const [CurrentTime, setCurrentTime] = useState(CurrentDate.getHours());

    useEffect(() => {

        if (ChatInfo !== undefined && ChatInfo !== null && ChatInfo !== '') {
            onGetData();
            setInterval(() => {
                onGetData();
            }, 30000);
        }
    }, []);

    // useEffect(() => {
    //     GetId();
    // }, [Area]);

    const MessageHandleKeyDown = (e) => {
        if (e.key === 'Enter') {
            onSend();
        }
    }

    const onStartChat = () => {
        if (Name === "") {
            Alertwarning(I18n.t("ContactForm.RequiredName"));
            NameRef.current.focus();
            return;
        } else if (Email === "") {
            Alertwarning(I18n.t("ContactForm.RequiredEmail"));
            EmailRef.current.focus();
            return;
        } else if (Phone === "") {
            Alertwarning(I18n.t("ContactForm.RequiredPhone"));
            PhoneRef.current.focus();
            return;
        } else if (TypeContact === "") {
            Alertwarning(I18n.t("ContactForm.RequiredType"));
            return;
        }
        else if (Area === "") {
            Alertwarning(I18n.t("ContactForm.RequiredArea"));
            return;
        } else {
            setDisabled(true);
            //Lưu key chat vào localstorage
            const userChat = CHAT_USERS.find(p => p.Type === TypeContact && p.Area === Area);
            if (userChat !== undefined) {
                let pr = {
                    NameSend: Name?.replace(/[`~!#$^&*()_|+\=;'",.<>\{\}\[\]\\\/]/gi, ''),
                    Mail: Email,
                    Phone: Phone?.replace(/[`~!#$^&*()_|+\=;'",.<>\{\}\[\]\\\/]/gi, ''),
                    TypeContact: TypeContact,
                    StartTime: new Date(),
                    Area: Area,
                    ToId: userChat.UserId
                }
                let ensc = EncodeString(JSON.stringify(pr));
                localStorage.setItem("ChatInformation", ensc);
                setChatInfo(ensc);
                setChatTemp([]);
                onReset();
                setDisabled(false);
                setInterval(() => {
                    onGetData();
                }, 30000);
            }
            const groupChat = CHAT_GROUPS.find(p => p.Type === TypeContact && p.Area === Area);
            if (groupChat !== undefined) {
                let pr = {
                    NameSend: Name?.replace(/[`~!#$^&*()_|+\=;'",.<>\{\}\[\]\\\/]/gi, ''),
                    Mail: Email,
                    Phone: Phone?.replace(/[`~!#$^&*()_|+\=;'",.<>\{\}\[\]\\\/]/gi, ''),
                    TypeContact: TypeContact,
                    StartTime: new Date(),
                    Area: Area,
                    ToId: groupChat.UserId
                }
                let ensc = EncodeString(JSON.stringify(pr));
                localStorage.setItem("ChatInformationGroup", ensc);
                setChatInfoGroup(ensc);
            }
        }
    }

    const onStopChat = () => {
        if (window.confirm("Bạn muốn thoát khỏi cuộc trò chuyện ?")) {
            localStorage.setItem("ChatInformation", "");
            localStorage.setItem("ChatbotInformation", "");
            setChatInfo("");
            setChatBotInfo("");
            setChatTemp([]);
            window.location.reload();
        }
    }

    const onReset = () => {
        setName("");
        setPhone("");
        setEmail("");
    }

    const onSelectGender = (item) => {
        setGenderId(item.value);
        setGender(item.label);
    }

    const onSelectCS = (item) => {
        setCSId(item.value);
        setCSName(item.label);
    }


    const onSend = async () => {
        if (Message === "") {
            Alertwarning(I18n.t("Chat.RequiredMessage"));
            MessageRef.current.focus();
            return;
        } else {
            setDisabled(true);
            //Lưu key chat vào localstorage
            let pr = JSON.parse(DecodeString(ChatInfo));
            pr.func = "SendMessage";
            pr.Message = Message;

            if (ChatInfo !== undefined && ChatInfo !== null && ChatInfo !== '') {
                try {
                    const list = await mainAction.API_spCallServerChat(pr, dispatch);
                    let _tmp = [...ChatTemp];
                    _tmp.push(Message);
                    setChatTemp(_tmp);
                    setMessage("");
                    onGetData();
                    setDisabled(false);
                } catch (err) {
                    setDisabled(false);
                }
            }

            const userChat = CHAT_USERS_LIST.filter(p => p.Type === TypeContact && p.Area === Area);
            let mes = '<h3>Customer send message from website netco.com.vn</h3>'
                + "<p>Name: <b>" + pr.NameSend + "</b><br />"
                + "Email: <b>" + pr.Mail + "</b><br />"
                + "Tel: <b>" + pr.Phone + "</b><br />"
                + "Area: <b>" + pr.Area + "</b><br />"
                + "Message: <b>" + Message + "</b></p>";
            userChat.forEach((element, index) => {
                try {
                    const NotifiParam = {
                        Json: JSON.stringify({
                            CustomerId: 0,
                            FuncSend: "NotifyChat",
                            HtmlNofity: mes,
                            UserReceipt: element.UserId
                        }),
                        func: "APIC_spSendNotification",
                    };
                    const resultNotify = mainAction.API_spCallServerSystem(
                        NotifiParam,
                        dispatch
                    );
                } catch (err) {
                    setDisabled(false);
                }
            })

            /* if (ChatInfoGroup !== undefined && ChatInfoGroup !== null && ChatInfoGroup !== '') {
                let pr = JSON.parse(DecodeString(ChatInfoGroup));
                pr.func = "SendMessage";
                pr.Message = "<p>Khách hàng gửi tin nhắn đến khu vực của bạn:</p>" + Message;
                try {
                    const list = await mainAction.API_spCallServerChat(pr, dispatch);
                    let _tmp = [...ChatTemp];
                    _tmp.push(Message);
                    setChatTemp(_tmp);
                    setMessage("");
                    onGetData();
                    setDisabled(false);
                } catch (err) {
                    setDisabled(false);
                }
            } */
        }
    }

    const onGetData = async () => {
        try {
            if (ChatInfo !== undefined && ChatInfo !== null && ChatInfo !== '') {
                //Lưu key chat vào localstorage
                let pr = JSON.parse(DecodeString(ChatInfo));
                pr.func = "GetMessage";
                const list = await mainAction.API_spCallServerChat(pr, dispatch);
                setData(list);
                setChatTemp([]);
            }
        } catch (err) {
            setDisabled(false);
        }
    }
    const Trl_spStaff_Login_Click = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(showPosition, showError);
        }
        else {
            // Alerterror("Không thể lấy vị trí của bạn, mở định vị!");
            return
        }
        // Trl_spStaff_Login(0, 0); // tạm thời ẩn chưa lấy vị trí
    }
    const showError = (error) => {
        switch (error.code) {
            case error.PERMISSION_DENIED:
                // Alerterror("User denied the request for Geolocation.");
                break;
            case error.POSITION_UNAVAILABLE:
                // Alerterror("Location information is unavailable.");
                break;
            case error.TIMEOUT:
                // Alerterror("The request to get user location timed out.");
                break;
            case error.UNKNOWN_ERROR:
                // Alerterror("An unknown error occurred.");
                break;
        }
    }
    const showPosition = (position) => {

         let lat = position.coords.latitude.toString(),
            lon = position.coords.longitude.toString();

            localStorage.setItem('lat',lat)
            localStorage.setItem('long',lon) 

        // console.log('lat',lat,'lon',lon)
    }
    const GetId = () => {

        if (Name === "") {
            Alertwarning(I18n.t("ContactForm.RequiredName"));
            return;
        }
        if (Email === "") {
            Alertwarning(I18n.t("ContactForm.RequiredEmail"));
            return;
        }
        if (Phone === "") {
            Alertwarning(I18n.t("ContactForm.RequiredPhone"));

            return;
        }
        // if (GenderId === 0) {
        //     Alertwarning(I18n.t("ContactForm.RequiredGender"));

        //     return;
        // }
        let min = 1;
        let max = 100000;
        let id = parseInt(min + (Math.random() * (max - min)));
        let idbot = id + 1;
        let NameBot = Name + 'Bot'
        localStorage.setItem('IdKh', id);
        localStorage.setItem('IdBot', idbot)
        localStorage.setItem('Name', Name);
        localStorage.setItem('Email', Email);
        localStorage.setItem('Phone', Phone);
        localStorage.setItem('Area', Area);
        localStorage.setItem('NameBot', NameBot);
        localStorage.setItem('Address', Address);
        localStorage.setItem('Gender', Gender);
        const userChat = CHAT_USERS.find(p => p.Area === Area);
        if (userChat !== undefined) {
            let pr = {
                NameSend: Name,
                Mail: Email,
                Phone: Phone,
                StartTime: new Date(),
                Area: Area,
                ToId: userChat.UserId
            }
            let ensc = EncodeString(JSON.stringify(pr));
            localStorage.setItem("ChatbotInformation", ensc);
            setChatBotInfo(ensc);
            setChatTemp([]);
            onReset();
            setDisabled(false);

        }
        const groupChat = CHAT_GROUPS.find(p => p.Area === Area);
        if (groupChat !== undefined) {
            let pr = {
                NameSend: Name,
                Mail: Email,
                Phone: Phone,
                StartTime: new Date(),
                Area: Area,
                ToId: groupChat.UserId
            }
            let ensc = EncodeString(JSON.stringify(pr));
            localStorage.setItem("ChatbotInformationGroup", ensc);
            setChatBotInfoGroup(ensc);

        }

    }

    return (

        <div className="card chat-area">
            <div className="card-header" id="headingChat">
                <h5 className="mb-0">
                    <a className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseChat" aria-expanded="false" aria-controls="collapseChat" onClick={Trl_spStaff_Login_Click}>

                        {I18n.t('Chat.ChatBot')} ( V 1.1.5.6)

                    </a>
                    <div className="pull-right">
                        {/* <i className='fa fa-commenting' title='Chat với Netco Post'
                                        onClick={SelectChatPost}></i> */}
                        <i className="fa fa-ellipsis-v" onClick={(e) => onStopChat()} title="Click để thoát khỏi cuộc trò chuyện"></i>
                        <i className="fa fa-minus" data-toggle="collapse" data-target="#collapseChat" aria-expanded="false" aria-controls="collapseChat"></i>

                    </div>
                </h5>
            </div>
            <div id="collapseChat" className="collapse" aria-labelledby="headingChat" data-parent="#accordion">
                <div className="card-body">
                    {ChatBotInfo === null || ChatBotInfo === "" || ChatBotInfo === undefined ? (
                        <div className="chat-form-start" id="collapseExample">
                            <div className="form">
                                <div className="form-group">
                                    <label className="special-label">{I18n.t("ContactPage.InputName")}</label>
                                    <input className="form-control" type="text" ref={NameRef} value={Name} {...bindName} maxLength="50" placeholder={I18n.t("ContactForm.InputName")} required />
                                </div>
                                
                                <div className="form-group">

                                    <label className="special-label">Anh/Chị muốn được xưng hô là gì</label>

                                    <SelectGender
                                        onSelected={e => onSelectGender(e)}
                                    />

                                </div>
                             
                                <div className="form-group">
                                    <label className="special-label">{I18n.t("ContactPage.InputPhone")}</label>
                                    <input className="form-control" type="text" ref={PhoneRef} value={Phone} {...bindPhone} maxLength="15" placeholder={I18n.t("ContactForm.InputPhone")} required />
                                </div>
                               
                                <div className="form-group">
                                    <label className="special-label">{I18n.t("Chat.Address")}</label>
                                    <input className="form-control" type="text" ref={AddressRef} value={Address} {...bindAddress} maxLength="50" placeholder={I18n.t("Chat.InputAddress")} required />
                                </div>

                                <div className="form-group">
                                    <label className="special-label">{I18n.t("ContactPage.InputEmail")}</label>
                                    <input className="form-control" type="text" ref={EmailRef} value={Email} {...bindEmail} maxLength="50" placeholder={I18n.t("ContactForm.InputEmail")} required />
                                </div>


                                <div className="form-group ">
                                    <label>{I18n.t("ContactForm.SelectYourArea")}</label>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <label className="form-check" onClick={(e) => setArea("North")}>
                                                {I18n.t("ContactForm.North")}
                                                <input type="radio" name="radioAreaChat" />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>
                                        <div className="col-md-4">
                                            <label className="form-check" onClick={(e) => setArea("Middle")}>
                                                {I18n.t("ContactForm.Middle")}
                                                <input type="radio" name="radioAreaChat" />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>
                                        <div className="col-md-4">
                                            <label className="form-check" onClick={(e) => setArea("South")}>
                                                {I18n.t("ContactForm.South")}
                                                <input type="radio" name="radioAreaChat" />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group text-right mb20">
                                    <button type="button" className="btn btn-success pd-30" disabled={disabled} onClick={(e) => GetId()}>{I18n.t("Chat.ChatNow")}</button>
                                </div>
                            </div>
                        </div>
                    )
                        : (<ChatBot></ChatBot>)

                    }
                </div>

            </div>
        </div>



        //  Giao diện Chat với CSKH
        //     <div className="card chat-area">
        //         <div className="card-header" id="headingChat">
        //             <h5 className="mb-0">
        //                 <a className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseChat" aria-expanded="false" aria-controls="collapseChat">
        //                     {I18n.t('Chat.ChatPost')}
        //                 </a>
        //                 <div className="pull-right">
        //                     <i className='fa fa-commenting' title='Chat với Netco' onClick={SelectChatBot}></i>
        //                     <i className="fa fa-ellipsis-v" onClick={(e) => onStopChat()} title="Click để thoát khỏi cuộc trò chuyện"></i>
        //                     <i className="fa fa-minus" data-toggle="collapse" data-target="#collapseChat" aria-expanded="false" aria-controls="collapseChat"></i>
        //                 </div>
        //             </h5>
        //         </div>
        //         <div id="collapseChat" className="collapse" aria-labelledby="headingChat" data-parent="#accordion">
        //             <div className="card-body">
        //            
        //              ChatInfo === null || ChatInfo === "" || ChatInfo === undefined ? (
        //                         <div className="chat-form-start" id="collapseExample">
        //                             <div className="form">
        //                                 <div className="form-group">
        //                                     <label className="special-label">{I18n.t("ContactPage.InputName")}</label>
        //                                     <input className="form-control" type="text" ref={NameRef} value={Name} {...bindName} maxLength="50" placeholder={I18n.t("ContactForm.InputName")} required />
        //                                 </div>
        //                                 <div className="form-group">
        //                                     <label className="special-label">{I18n.t("ContactPage.InputEmail")}</label>
        //                                     <input className="form-control" type="text" ref={EmailRef} value={Email} {...bindEmail} maxLength="50" placeholder={I18n.t("ContactForm.InputEmail")} required />
        //                                 </div>
        //                                 <div className="form-group">
        //                                     <label className="special-label">{I18n.t("ContactPage.InputPhone")}</label>
        //                                     <input className="form-control" type="text" ref={PhoneRef} value={Phone} {...bindPhone} maxLength="15" placeholder={I18n.t("ContactForm.InputPhone")} required />
        //                                 </div>
        //                                 <div className="form-group mb0">
        //                                     <label>{I18n.t("ContactForm.WhatDoYouNeed")}</label>
        //                                     <div className="row">
        //                                         <div className="col-md-12">
        //                                             <label className="form-check" onClick={(e) => setTypeContact("ShippingConsulting")}>
        //                                                 {I18n.t("ContactForm.ShippingConsulting")}
        //                                                 <input type="radio" name="radioChatType" />
        //                                                 <span className="checkmark"></span>
        //                                             </label>
        //                                         </div>
        //                                         <div className="col-md-12">
        //                                             <label className="form-check" onClick={(e) => setTypeContact("CustomerCare")}>
        //                                                 {I18n.t("ContactForm.CustomerCare")}
        //                                                 <input type="radio" name="radioChatType" />
        //                                                 <span className="checkmark"></span>
        //                                             </label>
        //                                         </div>
        //                                     </div>
        //                                 </div>
        //                                 <div className="form-group mb0">
        //                                     <label>{I18n.t("ContactForm.SelectYourArea")}</label>
        //                                     <div className="row">
        //                                         <div className="col-md-4">
        //                                             <label className="form-check" onClick={(e) => setArea("North")}>
        //                                                 {I18n.t("ContactForm.North")}
        //                                                 <input type="radio" name="radioAreaChat" />
        //                                                 <span className="checkmark"></span>
        //                                             </label>
        //                                         </div>
        //                                         <div className="col-md-4">
        //                                             <label className="form-check" onClick={(e) => setArea("Middle")}>
        //                                                 {I18n.t("ContactForm.Middle")}
        //                                                 <input type="radio" name="radioAreaChat" />
        //                                                 <span className="checkmark"></span>
        //                                             </label>
        //                                         </div>
        //                                         <div className="col-md-4">
        //                                             <label className="form-check" onClick={(e) => setArea("South")}>
        //                                                 {I18n.t("ContactForm.South")}
        //                                                 <input type="radio" name="radioAreaChat" />
        //                                                 <span className="checkmark"></span>
        //                                             </label>
        //                                         </div>
        //                                     </div>
        //                                 </div>
        //                                 <div className="form-group text-right mb20">
        //                                     <div className="col-sm-12 col-md-6 mb-2">
        //                                         <button type="button" className="btn btn-success pd-30" disabled={disabled} onClick={(e) => onStartChat()}>{I18n.t("Chat.ChatNow")}</button>
        //                                     </div>
        //                                 </div>
        //                             </div>
        //                         </div>
        //                     ) : (
        //                         <div className="chat-content">
        //                             <div className="list-message">
        //                                 <div key="msg0" className="msg in">
        //                                     <img className="msg-avatar" src="/assets/img/logo-chat.png" width="30" height="30" />
        //                                     <div className="msg-content">{I18n.t("Chat.CanIHelpYou")}</div>
        //                                 </div>
        //                                 {
        //                                     Data.map((item, index) => {
        //                                         if (CHAT_LIST_USER.indexOf(item.Id_Send) !== -1)
        //                                             return (
        //                                                 <div key={"msg" + index} className="msg in">
        //                                                     <img className="msg-avatar" src="/assets/img/logo-chat.png" width="30" height="30" />
        //                                                     <div className="msg-content">
        //                                                         {item.Message}
        //                                                         <div className="msg-time">{FormatDateJson(item.Creatdate)}</div>
        //                                                     </div>
        //                                                 </div>
        //                                             )
        //                                         else
        //                                             return (
        //                                                 <>
        //                                                     <div key={"msg" + index} className="msg out">
        //                                                         {item.Message}
        //                                                         <div className="msg-time">{FormatDateJson(item.Creatdate)}</div>
        //                                                     </div>
        //                                                     {(index + 1 === Data.length ? (<div key={"msg" + index} className="msg in">
        //                                                         <img className="msg-avatar" src="/assets/img/logo-chat.png" width="30" height="30" />
        //                                                         <div className="msg-content">
        //                                                             {I18n.t("Chat.AutoChat")}
        //                                                             <div className="msg-time">{FormatDateJson(item.Creatdate)}</div>
        //                                                         </div>
        //                                                     </div>) : (<></>))}
        //                                                 </>
        //                                             )

        //                                     })
        //                                 }
        //                                 {
        //                                     ChatTemp.map((item, index) => {
        //                                         return (<div key={"msg_tmp" + index} className="msg out">
        //                                             {item}
        //                                             <div className="msg-time">{FormatDateJson(new Date())}</div>
        //                                         </div>)
        //                                     })
        //                                 }
        //                             </div>
        //                             <div className="form-group chat-input-group">
        //                                 <div className="input-group">
        //                                     <input className="form-control" type="text" onKeyDown={(e) => MessageHandleKeyDown(e)} ref={MessageRef} value={Message} {...bindMessage} placeholder={I18n.t("Chat.InputMessage")} required />
        //                                     <div className="input-group-append">
        //                                         <button type="button" className="btn btn-success" onClick={(e) => onSend()}>{I18n.t("Chat.Send")}</button>

        //                                     </div>
        //                                 </div>
        //                             </div>
        //                         </div>
        //                     )
        //                 }
        //             </div>
        //         </div>
        //     </div>
        //   )} 
    )
}

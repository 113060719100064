import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { APIKey, FILE_URL } from "../../Services/Api";
import { mainAction } from "../../Redux/Actions";
import { FormatDateJson, ScrollTop } from "../../Utils";
import I18n from '../../Language';
import { DataTable } from "../../Common/DataTable";
import { NavItem } from "reactstrap";
import MainLayout from "../../Layout/MainLayout";

export const Carreers = () => {
    const dispatch = useDispatch();

    const [Info, setInfo] = useState([]);
    const [Info2, setInfo2] = useState([]);

    useEffect(() => {
        onGetData();
        onGetData2();
    }, [])

    const onGetData = async () => {
        const params = {
            Json: '{"Take":50}',
            func: "WS_spGetRecruitment",
        };

        try {
            const list = await mainAction.CallApiWs(params, dispatch);
            setInfo(list);
            ScrollTop();
        } catch (err) {
        }
    };

    const onGetData2 = async () => {
        const params = {
            Json: '{"Type":2,"Take":5}',
            func: "WS_spGetNews",
        };

        try {
            const list = await mainAction.CallApiWs(params, dispatch);
            setInfo2(list);
        } catch (err) {
        }
    }

    const columns = [
        {
            Header: "Chức danh",
            accessor: "Name",
            Cell: ({ row }) => (
                <Link to={"/thong-tin-tuyen-dung?title=" + row._original.Url}>{row._original.Name}</Link>
            ),
            Filter: ({ filter, onChange }) => (
                <input
                    onChange={(event) => onChange(event.target.value)}
                    value={filter ? filter.value : ""}
                    placeholder="Tìm theo chức danh"
                    className="form-control"
                />
            ),
        },
        {
            Header: "Nơi làm việc",
            accessor: "City",
            maxWidth: 150,
            Filter: ({ filter, onChange }) => (
                <input
                    onChange={(event) => onChange(event.target.value)}
                    value={filter ? filter.value : ""}
                    placeholder="Tìm theo nơi làm việc"
                    className="form-control"
                />
            ),
        },
        {
            Header: "Mức lương",
            accessor: "Salary",
            maxWidth: 150,
            Filter: ({ filter, onChange }) => (
                <input
                    onChange={(event) => onChange(event.target.value)}
                    value={filter ? filter.value : ""}
                    placeholder="Tìm theo mức lương"
                    className="form-control"
                />
            ),
        },
        {
            Header: "Hạn nhận hồ sơ",
            accessor: "EndDate",
            maxWidth: 150,
            Cell: (row) => (
                <>{FormatDateJson(row.value, 1)}</>
            ),
            Filter: ({ filter, onChange }) => (
                <input
                    onChange={(event) => onChange(event.target.value)}
                    value={filter ? filter.value : ""}
                    placeholder="Tìm theo hạn nhận hồ sơ"
                    className="form-control"
                />
            ),
        },
        {
            Header: "",
            maxWidth: 100,
            Cell: ({ row }) => (
                <Link className="btn btn-sm btn-success" to={"/thong-tin-tuyen-dung?title=" + row._original.Url}><i className="fa fa-eye"></i> Chi tiết</Link>
            ),
            filterable: false,
        },
    ];

    return (
        <MainLayout>
        <section className="content">
            <div className="container">
                <div className="sidemap">
                    <span><Link to="/">{I18n.t("TopMenu.Home")}</Link></span>
                    <span><i className="fa fa-angle-right"></i></span>
                    <span>{I18n.t("TopMenu.Carreers")}</span>
                </div>
                <div className="row">
                    <h3>{I18n.t('TopMenu.Carreers')}</h3>
                    <div className="row">
                        <div className="col-md-12 bg-white">
                            <DataTable data={Info} style={{ verticalAlign: "middle" }} columns={columns} />
                        </div>
                    </div>
                    {/* <div className="col-md-3 mt20">
                        <h5>{I18n.t('News.TitleActivate')}
                            <div className="pull-right text-sm pt10">
                                <Link className="link-blue" to={"/hoat-dong"}>{I18n.t('News.ReadMore')}</Link>
                            </div>
                        </h5>
                        <div className="row">
                            {
                                Info2.map((item, index) => {
                                    return (
                                        <div className="col-md-12" key={"news2" + index}>
                                            <div className="box-news-right">
                                                <Link to={"/chi-tiet-hoat-dong?title=" + item.Url}>
                                                    <img className="img-thumb" src={FILE_URL + item.ImgThumb} alt={item.Title} />
                                                    <div className="item-right">
                                                        <div className="text-sm">{item.Name}</div>
                                                        <div className="text-xs"><i className="fa fa-clock"></i> {FormatDateJson(item.CreateOn, 1)}</div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div> */}
                </div>
            </div>
        </section>
        </MainLayout>
    )
}
import React from "react";
import ReactHtml from 'raw-html-react';
import { Link } from "react-router-dom";

import { PostOffice } from "../../Common";
import MainLayout from "../../Layout/MainLayout";
import I18n from '../../Language';

export const Branch = () => {
    return (
        <MainLayout>
        <section className="content">
            <div className="container">
                <div className="sidemap">
                    <span><Link to="/" title={I18n.t("TopMenu.Home")}>{I18n.t("TopMenu.Home")}</Link></span>
                    <span><i className="fa fa-angle-right"></i></span>
                    <span>{I18n.t("PostOfficePage.Title")}</span>
                </div>
                <h3>{I18n.t('PostOfficePage.Title')}</h3>
                <div className="text-lg-bold mb10"><ReactHtml html={I18n.t('PostOfficePage.ShortDesc')} componentMap={{ Branch }} /></div>
                <div className="text mb10"><ReactHtml html={I18n.t('PostOfficePage.LongDesc')} componentMap={{ Branch }} /></div>
                <PostOffice />
            </div>
        </section >
        </MainLayout>
    )
}
import { mainTypes } from ".";

export function closeError(params, cb) {
    return {
        type: mainTypes.ERROR,
        params,
        cb,
    }
}

export function changeLanguage(params, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: mainTypes.CHANGE_LANGUAGE,
            params,
            resolve,
            reject
        })
    })
}

export function checkLanguage() {
    return {
        type: mainTypes.CHECK_LANGUAGE,
    }
}

export function LOADING(params, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: mainTypes.LOADING,
            params,
            resolve,
            reject
        })
    })
}

export function CallApiWs(params, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: mainTypes.CallApiWs,
            params,
            resolve,
            reject
        })
    })
}

export function API_UploadFile(params, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: mainTypes.API_UploadFile,
            params,
            resolve,
            reject
        })
    })
}

export function API_spCallServerSystem(params, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: mainTypes.API_spCallServerSystem,
            params,
            resolve,
            reject
        })
    })
}

export function API_spCallServerChat(params, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch({
            type: mainTypes.API_spCallServerChat,
            params,
            resolve,
            reject
        })
    })
}
import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import ReactHtml from 'raw-html-react';
import CountUp from 'react-countup';
import LazyLoad from 'react-lazyload';

import I18n from '../../Language';
import { useInput } from "../../Hooks";
import { APIKey, FILE_URL, APIKeySystem, GOOGLE_MAP_API_KEY, GOOGLE_MAP_ZOOM, GOOGLE_MAP_CENTER, CUSTOMER_CREATE_LADING_URL } from "../../Services/Api";
import { mainAction } from "../../Redux/Actions";
import { Alertwarning } from "../../Utils";
import { MapMarker, ContactForm, TrackingPrice, PostOffice } from "../../Common";
import MainLayout from "../../Layout/MainLayout";

export const Home = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [disabled, setDisabled] = useState(false);

    //#region Get Data

    const [LogoPartner, setLogoPartner] = useState([]);
    const [DomesticService, setDomesticService] = useState([]);
    const [InternationalService, setInternationalService] = useState([]);
    const [Statistic, setStatistic] = useState([]);
    const [Banner, setBanner] = useState([]);
    const [Apps, setApps] = useState([]);

    useEffect(() => {
        onGetData();
    }, []);

    const onGetData = async () => {
        let keyLang = localStorage.getItem("keyLang");
        const params = {
            Json: JSON.stringify([
                { "Type": "Banner", "Enabled": 1, "Lang": keyLang },
                { "Type": "Service", "Enabled": 1, "Lang": keyLang },
                { "Type": "Partner", "Enabled": 1, "Lang": keyLang },
                { "Type": "Statistic", "Enabled": 1, "Lang": keyLang },
                { "Type": "Apps", "Enabled": 1, "Lang": keyLang }]),
            func: "WS_spGetInformation",
        };

        try {
            const list = await mainAction.CallApiWs(params, dispatch);
            setBanner(list.filter(p => p.Type === "Banner"))
            setDomesticService(list.filter(p => p.Type === "Service" && p.Keys === "Domestic" && p.InHome === 1));
            setInternationalService(list.filter(p => p.Type === "Service" && p.Keys === "International" && p.InHome === 1));
            setLogoPartner(list.filter(p => p.Type === "Partner"));
            setApps(list.filter(p => p.Type === "Apps"));
            setStatistic(list.filter(p => p.Type === "Statistic"));
        } catch (err) {
        }
    };

    //#endregion

    //#region BANNER

    const BannerHtml = (
        <section className="banner">
            <div id="carousel-example-generic" className="carousel slide" data-ride="carousel">
                <ol className="carousel-indicators">
                    {Banner.map((item, index) => {
                        return (
                            <li className={index === 0 ? "active" : ""} key={item.Id + "banner"} data-target="#carousel-example-generic" data-slide-to={index}></li>
                        )
                    })
                    }
                </ol>
                <div className="carousel-inner">
                    {
                        Banner.map((item, index) => {
                            return (
                                <div className={index === 0 ? "item active" : "item"} key={item.Id + "BN"}>
                                    <img src={FILE_URL + item.ImgFull} alt={item.Title} style={item.Keys !== "1" ? { filter: "brightness(60%)" } : {}} />
                                    <div className="carousel-caption">
                                        <h5 className="h1"><ReactHtml html={item.Title} componentMap={{ Home }} /></h5>
                                        <p>{item.ShortDesc}</p>
                                        {item.InHome === 1 ? (<a title={item.Title} target="_blank" href={item.Url} className="btn btn-action" rel="noopener noreferrer">Xem chi tiết</a>) : (<></>)}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <a className="left carousel-control" href="#carousel-example-generic" role="button" data-slide="prev">
                    <span className="glyphicon glyphicon-chevron-left"><i className="fa fa-chevron-left"></i></span>
                </a>
                <a className="right carousel-control" href="#carousel-example-generic" role="button" data-slide="next">
                    <span className="glyphicon glyphicon-chevron-right"><i className="fa fa-chevron-right"></i></span>
                </a>
            </div>
        </section >
    );

    //#endregion

    //#region TRA CỨU ĐƠN HÀNG | ƯỚC TÍNH CƯỚC PHÍ | HỆ THỐNG BƯU CỤC

    const [KeyAbsolute, setKeyAbsolute] = useState(1);

    const [Code, bindCode, setCode] = useInput("");
    const CodeRef = useRef();
    const CodeHandleKeyDown = (e) => {
        if (e.key === 'Enter') {
            if (Code !== "")
                history.push("/tra-cuu-don-hang?code=" + Code)
            else {
                Alertwarning(I18n.t("Lading.ValidateCode"));
                CodeRef.current.focus();
            }
        }
    }

    const onTrackingBill = (e) => {
        if (Code !== "")
            history.push("/tra-cuu-don-hang?code=" + Code);
        else {
            Alertwarning(I18n.t("Lading.ValidateCode"));
            CodeRef.current.focus();
        }
    }

    const AbsoluteHtml = (
        <section className="box-absolute">
            {KeyAbsolute !== 1 ? (<div className="mask-body" onClick={(e) => setKeyAbsolute(1)}></div>) : (<></>)}
            <div className="container">
                <div className="shadow">
                    <div className="box-title">
                        <ul>
                            <li className={KeyAbsolute === 1 ? "active hide-sm" : " hide-sm"} onClick={(e) => setKeyAbsolute(1)}><img alt="tracking bill" height="32" src={KeyAbsolute === 1 ? "/assets/img/icon32/search-active.png" : "/assets/img/icon32/search.png"} /> <span>{I18n.t('Banner.TrackingBill')}</span></li>
                            <li className={KeyAbsolute === 2 ? "active hide-sm" : " hide-sm"} onClick={(e) => setKeyAbsolute(2)}><img alt="tracking price" height="32" src={KeyAbsolute === 2 ? "/assets/img/icon32/checklist-active.png" : "/assets/img/icon32/checklist.png"} /> <span className="hide-xs">{I18n.t('Banner.CreateLading')} /</span> <span>{I18n.t('Banner.EstimatedPrice')}</span></li>
                            <li className={KeyAbsolute === 3 ? "active hide-sm" : " hide-sm"} onClick={(e) => setKeyAbsolute(3)}><img alt="PostOffice" height="32" src={KeyAbsolute === 3 ? "/assets/img/icon32/Location-active.png" : "/assets/img/icon32/Location.png"} /> <span>{I18n.t('Banner.PostOffice')}</span></li>
                            <li className="hide-md"><Link to="/tra-cuu-don-hang"><img alt="tracking bill" height="18" src="/assets/img/icon32/search-mb.png" /> <span>{I18n.t('Banner.TrackingBill')}</span> <i className="pull-right fa fa-chevron-right"></i></Link></li>
                            <li className="hide-md"><Link to="/uoc-tinh-cuoc-phi"><img alt="checklist" height="18" src="/assets/img/icon32/create-lading-mb.png" /> <span>{I18n.t('Banner.CreateLading')} /</span> <span>{I18n.t('Banner.EstimatedPrice')}</span> <i className="pull-right fa fa-chevron-right"></i></Link></li>
                            <li className="hide-md"><Link to="/diem-gui-hang"><img alt="Location" height="18" src="/assets/img/icon32/map-mb.png" /> <span>{I18n.t('Banner.PostOffice')}</span> <i className="pull-right fa fa-chevron-right"></i></Link></li>
                        </ul>
                    </div>
                    <div className="box-content hide-sm">
                        {KeyAbsolute === 1 ? (
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <div className="input-group">
                                            <input type="text" className="form-control" ref={CodeRef} value={Code} {...bindCode} placeholder={I18n.t('Banner.InputLadingCode')} onKeyDown={(e) => CodeHandleKeyDown(e)} />
                                            <div className="input-group-apped"><button type="button" onClick={(e) => onTrackingBill(e)} className="btn btn-success">{I18n.t('Banner.Search')}</button></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (<></>)}
                        {KeyAbsolute === 2 ? (
                            <TrackingPrice />
                        ) : (<></>)}
                        {KeyAbsolute === 3 ? (
                            <PostOffice key="PostOffice1" />
                        ) : (<></>)}
                    </div>
                </div>
            </div>
        </section>
    );

    //#endregion

    //#region DỊCH VỤ

    const [TypeService, setTypeService] = useState(1);

    const ServicesHtml = (
        <section className="services">
            <div className="container">
                <div className="row mt70">
                    <div className="col-md-4 hide-xs">
                        <img alt="Netco Post" className="left-img" src="/assets/img/shipper2.png" />
                    </div>
                    <div className="col-md-8 text-center box-service mt30">
                        <h2 className="mt30 pt30"><ReactHtml html={I18n.t('Service.MultipleService')} componentMap={{ Home }} /></h2>
                        <div className="text-lg-bold"><span className={TypeService === 1 ? "active cursor" : "cursor"} onClick={(e) => setTypeService(1)}>{I18n.t("Service.Domestic")}</span> | <span className={TypeService === 2 ? "active cursor" : "cursor"} onClick={(e) => setTypeService(2)}>{I18n.t("Service.International")}</span></div>
                        {
                            TypeService === 1 ? (
                                <ul className="service-child">
                                    {
                                        DomesticService.map((item, index) => {
                                            return (
                                                <li key={item.Id + "SERVICE"}>
                                                    <Link title={item.Title} to={"/dich-vu?title=" + item.Url}>
                                                        <div className="box-item">
                                                            <img alt={item.Title} className="img-normal" src={FILE_URL + item.ImgThumb} />
                                                            <img alt={item.Title} className="img-hover" src={FILE_URL + item.ImgFull} />
                                                            <div className="text">{item.Title}</div>
                                                        </div>
                                                    </Link>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            ) : (
                                <ul className="service-child">
                                    {
                                        InternationalService.map((item, index) => {
                                            return (
                                                <li key={item.Id + "SERVICE"}>
                                                    <Link title={item.Title} to={"/dich-vu?title=" + item.Url}>
                                                        <div className="box-item">
                                                            <img alt={item.Title} className="img-normal" src={FILE_URL + item.ImgThumb} />
                                                            <img alt={item.Title} className="img-hover" src={FILE_URL + item.ImgFull} />
                                                            <div className="text">{item.Title}</div>
                                                        </div>
                                                    </Link>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            )}
                    </div>
                </div>
                <div className="clearfix"></div>
            </div>
        </section >
    )

    //#endregion

    //#region HỆ THỐNG BƯU CỤC

    const BranchHtml = (
        <section className="postoffices">
            <div className="container">
                <h2>{I18n.t('HomePage.BranchSearch')}</h2>
                <div className="text"><ReactHtml html={I18n.t('HomePage.BranchSearchDesc')} componentMap={{ Home }} /></div>
            </div>
        </section>
    )

    const ListPOHtml = (
        <section className="branch hide-xs">
            <div className="mask">
                <div className="container">
                    <PostOffice key="PostOffice2" />
                </div>
            </div>
        </section>
    )

    //#endregion

    //#region SLOGAN

    const SloganHtml = (
        <section className="slogan">
            <div className="container">
                <h1>NETCO POST</h1>
                <h3><ReactHtml html={I18n.t('HomePage.SloganDesc')} componentMap={{ Home }} /></h3>
            </div>
        </section>
    )

    //#endregion

    //#region ĐẾM COUNTDOWN

    const ProfiveHtml = (
        <section className="profive">
            <div className="container">
                <div className="row">
                    <div className="col-md-3 text-right">
                        {
                            Statistic.filter(p => p.Keys === "Left").map((item, index) => {
                                return (
                                    <div className="box-item" key={"Statistic" + item.Id}>
                                        <div className="text-title"><CountUp duration="5" end={item.Title} /></div>
                                        <div className="text-desc">{item.ShortDesc}</div>
                                        <div className="text-sm">{item.LongDesc}</div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="col-md-6">
                        <img alt="statictis" src="/assets/img/profive.png" />
                    </div>
                    <div className="col-md-3 text-left">
                        {
                            Statistic.filter(p => p.Keys === "Right").map((item, index) => {
                                return (
                                    <div className="box-item" key={"Statistic" + item.Id}>
                                        <div className="text-title"><CountUp duration="5" end={item.Title} /></div>
                                        <div className="text-desc">{item.ShortDesc}</div>
                                        <div className="text-sm">{item.LongDesc}</div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </section>
    )

    //#endregion

    //#region Khách hàng tiêu biểu

    const PartnersHtml = (
        <section className="partners">
            <div className="container">
                <h2><ReactHtml html={I18n.t('HomePage.PartnerTitle')} componentMap={Home} /></h2>
                <div className="text"><ReactHtml html={I18n.t('HomePage.PartnerDesc')} componentMap={Home} /></div>
                <div className="slider">
                    {
                        LogoPartner.map((item, index) => {
                            return (<div key={item.Id + "PARTNER"}><a href={item.Url} target="_blank" rel="noreferrer" title="item.Title"><img alt={item.Title} src={FILE_URL + item.ImgThumb} /></a></div>)
                        })
                    }
                </div>
            </div>
        </section>
    )

    //#endregion

    //#region FORM LIÊN HỆ VỚI NETCO

    const ContactsHtml = (
        <section className="contacts">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 hide-xs">
                        <div className="box-text">
                            <div className="h2"><ReactHtml html={I18n.t('HomePage.ContactSlogan')} componentMap={Home} /></div>
                            <div className="text-light"><ReactHtml html={I18n.t('HomePage.ContactHotline')} componentMap={Home} /></div>
                            <div className="h5 bold"><ReactHtml html={I18n.t('HomePage.ContactPhone')} componentMap={Home} /></div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="box-contact">
                            <div className="h5">{I18n.t("ContactForm.TitleContact")}</div>
                            <ContactForm key="home-contact" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )

    //#endregion

    //#region DOWNLOAD APP

    const DownloadHtml = (
        <section className="downloads bglightblue">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div className="box-img">
                            <img alt="download" src="/assets/img/dowloadapp.png" />
                           {/*  <img alt="mascot" className="mascotdowload" src="/assets/img/mascotlarge.png"/> */}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="box-qr">
                            <div className="h2">
                                <ReactHtml html={I18n.t('HomePage.DownloadAppTitle')} componentMap={Home} />
                            </div>
                            <div className="text-lg">
                                <ReactHtml html={I18n.t('HomePage.DownloadAppDesc')} componentMap={Home} />
                            </div>
                            <div className="row mt20 text-center">
                                <ul>
                                    {
                                        Apps.map((item, index) => {
                                            return (
                                                <li key={"App" + index}>
                                                    <a href={item.Url} target="_blank" rel="noreferrer" title={item.Title}>
                                                        <img style={item.Keys === "1" ? { height: "48px" } : { maxWidth: "180px" }} src={FILE_URL + item.ImgThumb} alt={item.Title} />
                                                    </a>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )

    //#endregion
    const [IsLoadMap, setIsLoadMap] = useState(0);
    return (
        <MainLayout>
        <>
            {BannerHtml}
            {AbsoluteHtml}
            {ServicesHtml}
            {ContactsHtml}

            {BranchHtml}

           {/*  <LazyLoad height={630} unmountIfInvisible> */}
                {IsLoadMap === 0 && (ListPOHtml)}
           {/*  </LazyLoad> */}
           
            {SloganHtml}
            {ProfiveHtml}
            {PartnersHtml}
            {DownloadHtml}
        </>
        </MainLayout>
    )
}

import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { mainAction } from "../Redux/Actions";
import Select from 'react-select';
import { APIKey } from "../Services";
// import { SelectTopic } from "../../Common";

const SelectGenderComp = React.forwardRef(({
    onSelected = () => { },
    value = 0,
    IsLoad = -1,
    title='Chọn giới tính'
    
},ref ) => {
    const dispatch = useDispatch();
    const [data, setData] = useState([
        { value: 0, label: "Chọn giới tính" },
        { value: 1, label: "Anh" },
        { value: 2, label: "Chị" }
    ])
    const [valueS, setValueS] = useState();
   
    const onSelecteItem = (item) => {

        onSelected(item)
        setValueS(item);
    }
  
    useEffect(() => {
        if (data.value !== 0) {
            const val = [...data].filter(p => p.value === value);
            if (val.length > 0)
                setValueS(val[0]);
        }

    }, [data.value])
    
    return (
        <Select className="SelectMeno"
            id="SelectMeno"
            value={valueS}
            onChange={onSelecteItem}
            options={data}
            ref={ref}
                     
        />
    )
}
);

export const SelectGender = React.memo(SelectGenderComp)
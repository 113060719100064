import axios from 'axios'

// export const API_WS = 'http://localhost:3369/api/apiws/'
export const API_WS = 'https://admin-netco.vps.vn/api/apiws/'
export const APIKey = 'netCoApi2022'

export const API_ERP = 'https://api-v4-erp.vps.vn/api'
export const APIKeyErp = 'netcoApikey2025'

export const API_CHAT = 'https://erp-chat.vps.vn/api/ApiMB'
// export const API_CHAT = 'http://localhost:6451/api/ApiMB'


export const FILE_URL = 'https://admin-netco.vps.vn/';
export const GOOGLE_LOGIN_CLIENTID = '418580183625-h3psg5ke3ri923qg5kuos64jmr0j2fuj.apps.googleusercontent.com'
export const FACEBOOK_LOGIN_APPID = '836612320191788'

export const GOOGLE_MAP_API_KEY = 'AIzaSyAl8WZfFte7tdA-GgRC281-c8ufJdEGtd4'
//export const GOOGLE_MAP_API_KEY = 'AIzaSyBUBW5JbPqpurOUq2iV3Ys3rx59IktH1-s' //'AIzaSyBdzbUGthJC0EQAmUsAXgh4J0OUN9uVh4g' //

export const GOOGLE_MAP_ZOOM = 5;
export const GOOGLE_MAP_CENTER = [14.775869, 106.688661];
export const GOOGLE_MAP_LOCATION = { lat: 14.775869, lng: 106.688661 };
export const CUSTOMER_CREATE_LADING_URL = "https://customer.netco.com.vn/tao-nhanh-van-don"
export const CHAT_USERS = [
    { Type: "CustomerCare", Area: "North", UserId: 3821 },
    { Type: "CustomerCare", Area: "Middle", UserId: 5175 },
    { Type: "CustomerCare", Area: "South", UserId: 5176 },
    { Type: "ShippingConsulting", Area: "North", UserId: 5138 },
    { Type: "ShippingConsulting", Area: "Middle", UserId: 5138 },
    { Type: "ShippingConsulting", Area: "South", UserId: 5138 }
   /*  { Type: "ShippingConsulting", Area: "Middle", UserId: 5177 },
    { Type: "ShippingConsulting", Area: "South", UserId: 5178 } */
]
export const CHAT_USERS_LIST = [
    { Type: "CustomerCare", Area: "North", UserId: 666 },//Nguyễn Thu Hường | Chief Customer Office  | NTC
    { Type: "CustomerCare", Area: "Middle", UserId: 666 },//Nguyễn Thu Hường | Chief Customer Office  | NTC
    { Type: "CustomerCare", Area: "South", UserId: 666 },//Nguyễn Thu Hường | Chief Customer Office  | NTC
    { Type: "ShippingConsulting", Area: "North", UserId: 666 },//Nguyễn Thu Hường | Chief Customer Office  | NTC
    { Type: "ShippingConsulting", Area: "Middle", UserId: 666 },//Nguyễn Thu Hường | Chief Customer Office  | NTC
    { Type: "ShippingConsulting", Area: "South", UserId: 666 },//Nguyễn Thu Hường | Chief Customer Office  | NTC
    { Type: "CustomerCare", Area: "North", UserId: 2048 },//Thùy Phương
    { Type: "CustomerCare", Area: "North", UserId: 3801 },//Nguyễn Dung
    { Type: "CustomerCare", Area: "Middle", UserId: 627 },//Lê Thị Kiểm
    { Type: "CustomerCare", Area: "South", UserId: 1070 },//Han Do
    { Type: "ShippingConsulting", Area: "North", UserId: 3739 },//Hiếu
    { Type: "ShippingConsulting", Area: "Middle", UserId: 3739 },//Hiếu
    { Type: "ShippingConsulting", Area: "South", UserId: 3739 },//Hiếu
    { Type: "ShippingConsulting", Area: "North", UserId: 5010 },//Phong
    { Type: "ShippingConsulting", Area: "Middle", UserId: 5010 },//Phong
    { Type: "ShippingConsulting", Area: "South", UserId: 5010 }//Phong
   /*  { Type: "ShippingConsulting", Area: "Middle", UserId: 5177 },
    { Type: "ShippingConsulting", Area: "South", UserId: 5178 } */
]

export const CHAT_LIST_USER = [3821, 5138, 5175, 5177, 5176, 5178]

export const CHAT_GROUPS = [
    { Type: "CustomerCare", Area: "North", UserId: "g6339" },
    { Type: "CustomerCare", Area: "Middle", UserId: "g6340" },
    { Type: "CustomerCare", Area: "South", UserId: "g6341" },
]

const setToken = (token) => {
    if (token) {
        axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    } else {
        delete axios.defaults.headers.common.Authorization;
    }
};

axios.defaults.headers.post["Content-Type"] = "application/json";

const apiWS = axios.create({
    baseURL: API_WS
})

const apiErp = axios.create({
    baseURL: API_ERP
})

const apiChat = axios.create({
    baseURL: API_CHAT
})

export default setToken;
export { apiWS, apiErp, apiChat };

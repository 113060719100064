import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

export const ThankPage = (props) => {
    const location = useLocation();
    useEffect(() => {
        if (props.location.state === 1) {
            setPageLabel('Đơn khiếu nại')
            setType(1)
        } else {
            setPageLabel('Đơn đăng ký tư vấn vận chuyển')
            setType(0)
        }
    }, [location.search]);

    const [PageLabel, setPageLabel] = useState('Đơn đăng ký tư vấn vận chuyển');
    const [Type, setType] = useState(0);
    return (
        <section className="thankpage">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="thankpage-inner">
                            <div className="thankpage-inner__head">
                                <img className="msg-avatar" src="/assets/img/logo.png" width="200" />
                            </div>
                            <div className="thankpage-inner__main">
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle-check" width="80" height="80" viewBox="0 0 24 24" stroke-width="1.5" stroke="#00884E" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                                    <path d="M9 12l2 2l4 -4" />
                                </svg>
                                <div className="message">
                                    <p>Cảm ơn bạn!</p>
                                    <p>{PageLabel} của bạn</p>
                                    <p>đã được ghi nhận.</p>
                                </div>
                                <div className="message-bottom">Vui lòng nghe máy khi có nhân viên chăm sóc liên lạc trong trong thời gian sớm nhất!</div>
                                <div className="mt40">
                                    {Type === 1 ?
                                        <Link to="/khieu-nai"> <a className="btn success"> Trở về </a></Link> :
                                        <Link to="/"> <a className="btn success"> Trở về </a></Link>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    )
}
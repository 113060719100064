import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useHistory } from "react-router-dom";
import ReactHtml from 'raw-html-react';

import { useInput } from "../../Hooks";
import { FILE_URL } from "../../Services/Api";
import { mainAction } from "../../Redux/Actions";
import { ScrollTop, FormatNumber, FormatDateJson, Alertwarning, Alertinfo } from "../../Utils";
import I18n from '../../Language';
import MainLayout from "../../Layout/MainLayout";
export const Lading = () => {
    const dispatch = useDispatch();
    const [disabled, setDisabled] = useState(false);

    const _history = useHistory();
    const location = useLocation();

    const [Info, setInfo] = useState({});
    const [History, setHistory] = useState([]);
    const [Products, setProduct] = useState([]);
    const [AnotherLading, setAnotherLading] = useState([]);

    const [TypeSearch, setTypeSearch] = useState("1");

    const [Code, bindCode, setCode] = useInput("");
    const CodeRef = useRef();

    const [IsShow, setIsShow] = useState(false);
    const [IsShowDetail, setIsShowDetail] = useState(false);

    useEffect(() => {
        const pr = new URLSearchParams(location.search);
        let type = "1";
        if (pr.get("type") !== null) type = pr.get("type");
        else type = "1";

        if (pr.get("code") !== null) {
            setCode(pr.get("code"));
            setTypeSearch(type);
            onGetDetail(pr.get("code"), type);
        }
        else
            setCode("");
        onGetApp();
    }, [location]);

    const onAction = () => {
        if (Code !== "")
            _history.push("/tra-cuu-don-hang?code=" + Code + "&type=" + TypeSearch)
        else {
            Alertwarning(I18n.t("Lading.ValidateCode"));
            setIsShow(false);
            setIsShowDetail(false);
            setInfo({});
            setHistory([]);
            setAnotherLading([]);
            ScrollTop();
        }
    }

    const onGetDetail = async (code, type) => {
        setIsShow(false);
        setIsShowDetail(false);
        setInfo({});
        setAnotherLading([]);
        setHistory([]);
        setProduct([]);
        if (code !== "" && code !== null && type === "1") {
            setDisabled(true);
            const params = {
                Json: '{\"Code\":\"' + code.trim() + '\",\"FinishMonth\":3}',
                func: "APIC_spLading_Find",
                IsLoading: true
            };

            try {
                const list = await mainAction.API_spCallServerSystem(params, dispatch);
                if (list.Detail !== undefined) {
                    setIsShow(true);
                    setIsShowDetail(true);
                    setInfo(list.Detail[0]);
                    setHistory(list.History);
                    setProduct(list.Products);
                    setAnotherLading([]);
                }
                setDisabled(false);
            } catch (err) {
                setDisabled(false);
            }
        } else if (code !== "" && code !== null && type === "2") {
            setDisabled(true);
            const params = {
                Json: '{\"Code\":\"' + code.trim() + '\",\"FinishMonth\":3}',
                func: "APIC_spLading_FindDOCode",
                IsLoading: true
            };

            try {
                const list = await mainAction.API_spCallServerSystem(params, dispatch);
                if (list.Detail !== undefined) {
                    setIsShow(true);
                    setIsShowDetail(false);
                    setInfo(list.Detail[0]);
                    setAnotherLading(list.Detail);
                }
                setHistory([]);
                setDisabled(false);
            } catch (err) {
                setDisabled(false);
            }
        }
    };

    /* const onCheckShowImage = () => {
        debugger
        if (CustomerCode !== "" && CustomerCode.toUpperCase() === Info?.CustomerCode.toUpperCase())
            setIsShowImage(true);
        else if (CustomerPhone !== "" && CustomerPhone === Info?.CustomerPhone)
            setIsShowImage(true);
        else
            setIsShowImage(false);
    } */


    //#region DOWNLOAD APP

    const [Apps, setApps] = useState([]);
    const onGetApp = async () => {
        let keyLang = localStorage.getItem("keyLang");
        const params = {
            Json: JSON.stringify([{ "Type": "Apps", "Enabled": 1, "Lang": keyLang }]),
            func: "WS_spGetInformation",
        };

        try {
            const list = await mainAction.CallApiWs(params, dispatch);
            setApps(list.filter(p => p.Type === "Apps"));
        } catch (err) {
        }
    };

    const DownloadHtmlMd = (
        <div className="row box-ld mt20">
            <div className="col-md-12 mb10">
                <h2 className="h5 text-center">
                    <span className="bolder text-red">Tải app NETCO Post để tra cứu hình ảnh và chữ ký người nhận</span>
                </h2>
            </div>
            <p style={{ color: "red", textAlign: "center" }}></p>
            <div className="row">
                <div className="col-md-6">
                    <div className="box-img">
                        <img alt="download" src="/assets/img/mobile-oval.png" />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="box-qr">
                        <div className="h2">
                            <ReactHtml id="DownloadAppTitle" html={I18n.t('HomePage.DownloadAppTitle')} componentMap={Lading} />
                        </div>
                        <div className="text-lg">
                            <ReactHtml id="DownloadAppDesc" html={I18n.t('HomePage.DownloadAppDesc')} componentMap={Lading} />
                        </div>
                        <div className="row mt20 text-center">
                            <ul>
                                {
                                    Apps.map((item, index) => {
                                        return (
                                            <li key={"App" + index}>
                                                <a href={item.Url} target="_blank" rel="noreferrer" title={item.Title}>
                                                    <img style={item.Keys === "1" ? { height: "48px" } : { maxWidth: "180px" }} src={FILE_URL + item.ImgThumb} alt={item.Title} />
                                                </a>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

    //#endregion

    const HtmlLading = (
        <>
            <div className="row box-ld mt20">
                <div className="col-md-12">
                    <h2 className="h5 mb10"><span className="bolder fz17">{I18n.t("Lading.CustomerCareLine")}</span></h2>
                    <div className="row">
                        <div className="col-md-4">
                            <i className="fa fa-user-o mr10"></i> {Info?.OfficerNameCS}
                        </div>
                        <div className="col-md-4">
                            <i className="fa fa-phone mr10"></i> {Info?.PhoneCS}
                        </div>
                        <div className="col-md-4">
                            <i className="fa fa-envelope-o mr10"></i> {Info?.EmailCS}
                        </div>
                    </div>
                </div>
            </div>

            {
                AnotherLading.length > 0 ? (
                    <div className="row box-ld mt20">
                        <div className="col-md-12">
                            <h2 className="h5 mb10"><span className="bolder fz17">{I18n.t("Lading.LadingSameDOCode")}</span>: <span className="text-green bolder">{Code}</span></h2>
                            <p>({I18n.t("Lading.LadingClickView")})</p>
                            <ul>
                                {
                                    AnotherLading.map(
                                        (item, index) => {
                                            return (
                                                <li key={"ladingcode" + index}>
                                                    <Link className="label label-success" to={"/tra-cuu-don-hang?code=" + item.Code}><i className="fa fa-eye mr10"></i> {item.Code}</Link>
                                                </li>
                                            );
                                        })
                                }
                            </ul>
                        </div>
                    </div>
                ) : (<></>)
            }

            {TypeSearch === "2" && !IsShowDetail ? (<></>) : (<>
                <div className="row box-ld mt20">
                    <div className="col-md-12 mb20">
                        <h2 className="h5">
                            <span className="bolder fz17">{I18n.t("Lading.LadingInformation")}: </span>
                            <span className="text-green text-lg-bolder">{Info?.Code}</span>
                            <div className="fl-r-mb text-lg-bolder">
                                {I18n.t("Lading.Status")}: <span className="text-green">{Info?.StatusName}</span>
                            </div>
                        </h2>
                    </div>
                    <div className="col-md-4">
                        <div className="green1">
                            <p><b>{I18n.t('Lading.CreateDate')}:</b> <span className="text">{FormatDateJson(Info?.CreateDate, 1)}</span></p>
                            <p>
                                <b>{I18n.t('Lading.DealineTime')}:</b> <span className="text">{I18n.t("Lading.EstimateTime")} {FormatDateJson(Info?.DealineTime, 1)}</span>
                            </p>

                            {Info?.FinishDate !== undefined && <p><b>{I18n.t('Lading.FinishDate')}:</b> <span className="text-bold text-green">{FormatDateJson(Info?.FinishDate, 1)}</span></p>}
                            {Info?.Recipient_reality !== undefined && <p><b>{I18n.t('Lading.RecipientReality')}:</b> <span className="text-bold text-green">{Info?.Recipient_reality}</span></p>}

                            <p><b>{I18n.t('Lading.ServiceName')}:</b> <span className="text">{Info?.ServiceName}</span></p>
                            <p><b>{I18n.t('Lading.Weight')}:</b> <span className="text">{FormatNumber(Info?.Weight)} gram</span></p>
                            <p><b>{I18n.t('Lading.Number')}:</b> <span className="text">{FormatNumber(Info?.Number)}</span></p>
                            {Info?.Mass !== 0 && <p><b>{I18n.t('Lading.Mass')}:</b> <span className="text">{FormatNumber(Info?.Mass)}</span></p>}
                            <p><b>{I18n.t('Lading.PaymentString')}:</b> <span className="text">{Info?.PaymentString}</span></p>
                            {Info?.Description !== "" && <p><b>{I18n.t('Lading.Description')}:</b> <span className="text">{Info?.Description}</span></p>}
                            {Info?.Noted !== "" && <p><b>{I18n.t('Lading.Noted')}:</b> <span className="text">{Info?.Noted}</span></p>}
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="green1">
                            {/* <h2 className="h5">{I18n.t("Lading.SenderInformation")}</h2> */}
                            <p><b>{I18n.t('Lading.PartnerCode')}:</b> <span className="text">{Info?.PartnerCode}</span></p>
                            <p><b>{I18n.t('Lading.CustomerName')}:</b> <span className="text">{Info?.CustomerName}</span></p>
                            <p><b>{I18n.t('Lading.CitySendCode')}:</b> <span className="text">{Info?.CitySendCode}</span></p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="green1">
                            {/* <h2 className="h5">{I18n.t("Lading.RecipientInformation")}</h2> */}
                            <p><b>{I18n.t('Lading.RecipientName')}:</b> <span className="text">{Info?.RecipientName}</span></p>
                            <p><b>{I18n.t('Lading.RecipientPhone')}:</b> <span className="text">{Info?.RecipientPhoneShow}</span></p>
                            <p><b>{I18n.t('Lading.RecipientAddress')}:</b> <span className="text">{Info?.RecipientAddressShow}</span></p>
                            <p><b>{I18n.t('Lading.CityRecipientCode')}:</b> <span className="text">{Info?.CityRecipientCode}</span></p>
                        </div>
                    </div>
                </div>
                {Products.length > 0 ? (
                    <div className="row box-ld mt20">
                        <div className="col-md-12 mb10">
                            <h2 className="h5">
                                <span className="bolder fz17">{I18n.t("Lading.Products")}</span>
                            </h2>
                        </div>
                        <div className="col-md-12">
                            <table id="dataTable" className="table table-primary">
                                <thead>
                                    <tr>
                                        <th width="150">{I18n.t("Lading.ProductCode")}</th>
                                        <th>{I18n.t("Lading.ProductName")}</th>
                                        <th>{I18n.t("Lading.Quanlity")}</th>
                                        <th>{I18n.t("Lading.ProductDescription")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Products.map((item, index) => {
                                        return (
                                            <tr key={index} className={'line' + index}>
                                                <td>{item.ProductCode}</td>
                                                <td>{item.ProductName}</td>
                                                <td>{item.Quanlity}</td>
                                                <td>{item.ProductDescription}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                ) : (<></>)}
                <div className="row box-ld mt20">
                    <div className="col-md-12 mb10">
                        <div className="row">
                            <div className="col-md-6">
                                <h2 className="h5">
                                    <span className="bolder fz17">{I18n.t("Lading.HistoryLading")}</span>
                                </h2>
                            </div>
                            <div className="col-md-6 ">
                                <span className="bolder fz17 fl-r-mb">
                                    <a href="https://customer.netco.com.vn fz15" className="text-blue" target="_blank"
                                        title={I18n.t("Lading.ImageDelivery")}>
                                        <img width="25" src="/assets/img/icon24/map.png" alt="map" />
                                        {I18n.t("Lading.FollowLading")}
                                    </a></span>
                            </div>
                        </div>

                    </div>
                    <div className="col-md-12 historytimeline">
                        {/*  <table id="dataTable" className="table table-primary">
                            <thead>
                                <tr>
                                    <th width="150">{I18n.t("Lading.DateTime")}</th>
                                    <th>{I18n.t("Lading.Position")}</th>
                                    <th>{I18n.t("Lading.Status")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {History.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{FormatDateJson(item.DateTime)}</td>
                                            <td>{item.POName}</td>
                                            <td>{item.Note}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table> */}
                        <div class="rightbox">
                            <div class="rb-container">
                                <ul class="rb">
                                    {History.map((item, index) => {
                                        return (
                                            /*   <tr key={index}>
                                                  <td>{FormatDateJson(item.DateTime)}</td>
                                                  <td>{item.POName}</td>
                                                  <td>{item.Note}</td>
                                              </tr> */
                                            <li class="rb-item" ng-repeat="itembx">
                                                <div class="item-title"> {item.Note}</div>

                                                <div class="timestamp">
                                                    <i class="fa fa-clock-o" aria-hidden="true"></i> {FormatDateJson(item.DateTime)}  ,  {item.POName}
                                                </div>

                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </>)}
            {DownloadHtmlMd}
        </>
    )

    return (
        <MainLayout>
            <>
                <section className="content">
                    <div className="container">
                        <div className="sidemap">
                            <span><Link to="/">{I18n.t("TopMenu.Home")}</Link></span>
                            <span><i className="fa fa-angle-right"></i></span>
                            <span>{I18n.t("Lading.Title")}</span>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <h3>{I18n.t('Lading.Title')}</h3>
                            </div>
                        </div>
                        <div className="row box-ld">
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label className="bold">{I18n.t('Lading.Code')} | {I18n.t('Lading.DOCode')}</label>
                                    <input type="text" ref={CodeRef} value={Code} {...bindCode} className="form-control" placeholder={I18n.t('Banner.InputLadingCode')} />
                                </div>
                            </div>
                            {/*  <div className="col-md-4">
                            <div className="form-group">
                                <label className="bold">{I18n.t('Lading.DOCode')}</label>
                                <input type="text" ref={DOCodeRef} value={DOCode} {...bindDOCode} className="form-control" placeholder={I18n.t('Lading.EnterDOCode')} />
                            </div>
                        </div> */}
                            <div className="col-md-3 mt30">
                                <label className="form-check" onClick={(e) => { setTypeSearch("1"); }}>
                                    {I18n.t("Lading.SearchByCode")}
                                    <input type="radio" name="radio" checked={TypeSearch === "1" ? true : false} value="1" />
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                            <div className="col-md-3 mt30">
                                <label className="form-check" onClick={(e) => { setTypeSearch("2"); }}>
                                    {I18n.t("Lading.SearchByDO")}
                                    <input type="radio" name="radio" checked={TypeSearch === "2" ? true : false} value="2" />
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                            <div className="col-md-2"><button type="submit" disabled={disabled} className="btn btn-success w-100 mt25" onClick={(e) => { onAction() }}>{I18n.t('Banner.Search')}</button></div>
                            <div className="clearfix"></div>
                            <p style={{ textAlign: "center", marginTop: '10px' }}>{I18n.t("Lading.ContactCEO")}</p>
                            {!IsShow ? <div style={{ textAlign: "center", fontSize: "20px" }}>Không tìm thấy vận đơn hoặc vận đơn đã vượt quá thời hạn tra cứu. Vui lòng tải app để xem thông tin</div> : <></>}
                        </div>
                        {!IsShow ? <></> : HtmlLading}
                    </div>
                </section>
                {/* {DownloadHtml} */}
            </>
        </MainLayout>
    )
}
import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useHistory } from "react-router-dom";
import MainLayout from "../../Layout/MainLayout";
import { TrackingPrice } from "../../Common";
import I18n from '../../Language';

export const PriceTracking = () => {
    const dispatch = useDispatch();

    return (
        <MainLayout>
        <section className="content">
            <div className="container">
                <div className="sidemap">
                    <span><Link to="/">{I18n.t("TopMenu.Home")}</Link></span>
                    <span><i className="fa fa-angle-right"></i></span>
                    <span>{I18n.t("Banner.EstimatedPrice")}</span>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <h3>{I18n.t('Banner.EstimatedPrice')}</h3>
                    </div>
                </div>
                <div className="row box-ld box-price">
                    <div className="col-md-12">
                        <TrackingPrice />
                    </div>
                </div>
            </div>
        </section>
        </MainLayout>
    )
}
import React, { useState, useEffect, useRef, useDebugValue } from "react";
import { useDispatch } from "react-redux";

import { mainAction } from "../Redux/Actions";
import { APIKeySystem } from "../Services";
import { GMap } from ".";

const MapViewComp = () => {
    const dispatch = useDispatch();

    const [DataFilterPO, setDataFilterPO] = useState([]);

    useEffect(() => {
        onGetData();
    }, []);

    //#region Get Danh sách bưu cục

    const onGetData = async () => {
        try {
            let pr = {
                Json: "",
                func: "APIC_spPostOffice_GetMany",
            };
            const data = await mainAction.API_spCallServerSystem(pr, dispatch);
            setDataFilterPO(data);
        } catch (err) {
        }
        mainAction.LOADING({ IsLoading: false }, dispatch);
    };

    //#endregion

    return (
        <div className="map-area">
            <GMap dataMarker={DataFilterPO} dataDirection={[]} dataZoom={{}} />
        </div>
    );
};

export const MapView = React.memo(MapViewComp);

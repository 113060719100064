import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useHistory } from "react-router-dom";
import ReactHtml from 'raw-html-react';
import MetaTags from 'react-meta-tags';

import { APIKey, FILE_URL, CUSTOMER_CREATE_LADING_URL } from "../../Services/Api";
import { mainAction } from "../../Redux/Actions";
import { ScrollTop, FormatDateJson, FormatDate } from "../../Utils";
import I18n from '../../Language';
import MainLayout from "../../Layout/MainLayout";

export const CarreersDetail = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();

    const [Info, setInfo] = useState({});
    const [List, setList] = useState([]);

    useEffect(() => {
        const pr = new URLSearchParams(location.search);
        if (pr.get("title") !== "") {
            onGetData(pr.get("title"));
            onGetList(pr.get("title"));
        }
        else
            history.push('/tuyen-dung');
    }, [location]);

    const onGetData = async (url) => {
        const params = {
            Json: '{"Url":"' + url + '"}',
            func: "WS_spGetRecruitment_Detail",
        };

        try {
            const list = await mainAction.CallApiWs(params, dispatch);
            let info = list[0];
            setInfo(info);
            ScrollTop();
        } catch (err) {
        }
    };

    const onGetList = async (url) => {
        const params = {
            Json: '{"Take":"6"}',
            func: "WS_spGetRecruitment",
        };

        try {
            const list = await mainAction.CallApiWs(params, dispatch);
            setList(list.filter(p => p.Url !== url));
            ScrollTop();
        } catch (err) {
        }
    };

    const SEO = (
        <MetaTags>
            <title>NETCO POST - {I18n.t("TopMenu.Carreers")} - {Info?.Name}</title>
            <meta name="description" content={Info?.MetaDescription} />
            <meta property="og:image" content={Info?.ImgThumb} />
            <meta property="og:title" content={Info?.Name} />
            <meta property="og:description" content={Info?.MetaDescription} />
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={window.location.href} />
            <meta property="og:site_name" content={Info?.Name} />
        </MetaTags>
    )

    return (
        <MainLayout>
        <section className="content">
            {SEO}
            <div className="container">
                <div className="sidemap">
                    <span><Link to="/">{I18n.t("TopMenu.Home")}</Link></span>
                    <span><i className="fa fa-angle-right"></i></span>
                    <span><Link to="/tuyen-dung">{I18n.t("TopMenu.Carreers")}</Link></span>
                    <span><i className="fa fa-angle-right"></i></span>
                    <span>{Info?.Name}</span>
                </div>
                <div className="row">
                    <div className="col-md-8">
                        <div className="pd15 bg-white radius pb30">
                            <h1 className="h3 mb10 pt10">{Info?.Name}</h1>
                            <p><b>Nơi làm việc:</b> {Info?.City}</p>
                            <p><b>Mức lương:</b> {Info?.Salary}</p>
                            <p><b>Hạn chót nhận hồ sơ:</b> {FormatDateJson(Info?.EndDate, 1)}</p>
                            <p className="mb10 mt10">
                                <Link to={"/ung-tuyen?title=" + Info.Url} className="btn btn-success btn-sm"><i className="fa fa-edit"></i> Nộp đơn</Link>
                            </p>
                            <hr />
                            <h4 className="title1 mb10 text-green">Phúc lợi</h4>
                            <div className="col-md-6 mb10 mt10 text-lg"><i className="fa fa-medkit"></i> Bảo hiểm</div>
                            <div className="col-md-6 mb10 mt10 text-lg"><i className="fa fa-plane"></i> Du Lịch</div>
                            <div className="col-md-6 mb10 text-lg"><i className="fa fa-usd"></i> Thưởng</div>
                            <div className="col-md-6 mb10 text-lg"><i className="fa fa-user-md"></i> Chăm sóc sức khỏe</div>
                            <div className="col-md-6 mb20 text-lg"><i className="fa fa-graduation-cap"></i> Đào tạo</div>
                            <div className="col-md-6 mb20 text-lg"><i className="fa fa-line-chart"></i> Tăng lương</div>
                            <ReactHtml className="text-justify mt30" html={Info?.Content?.replaceAll("src=\"/image/", "src=\"" + FILE_URL + "image/")?.replaceAll("src=\"/Image/", "src=\"" + FILE_URL + "image/")} componentMap={{ CarreersDetail }} />
                        </div>
                    </div>
                    <div className="col-md-4 sidebar-right">
                        <h5 className="mb20 pt20 bold">{I18n.t("Sidebar.CarreersAnother")}</h5>
                        <div className="row">
                            {
                                List.map((item, index) => {
                                    return (
                                        <div className="col-md-12" key={"news2" + index}>
                                            <div className="box-news-right">
                                                <Link to={"/thong-tin-tuyen-dung?title=" + item.Url}>
                                                    <img className="img-thumb" src={FILE_URL + item.ImgThumb} alt={item.Title} onerror="this.onerror = null; this.src = 'https://admin-netco.vps.vn/Image/ckfinder/files/logoNew.png';" />
                                                    <div className="item-right">
                                                        <div className="text-sm">{item.Name}</div>
                                                        <div className="text-xs"><i className="fa fa-clock"></i> {FormatDateJson(item.CreateOn, 1)}</div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            <div className="col-md-12 box-share mb10">
                                <div className="text-lg-bolder mb10 mt20">
                                    {I18n.t("News.ShareToSocial")}
                                </div>
                                <a target="_blank" rel="noreferrer" href={"https://www.facebook.com/sharer/sharer.php?u=" + window.location.href} onClick="javascript:window.open(this.href,'', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');return false;">
                                    <img src="/assets/img/fb-square.png" alt="Share on Facebook" />
                                </a>
                                <a target="_blank" rel="noreferrer" href={"https://plus.google.com/share?url=" + window.location.href} onClick="javascript:window.open(this.href,'', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');return false;">
                                    <img src="/assets/img/gg-square.png" alt="Share on Google+" />
                                </a>
                                <a target="_blank" rel="noreferrer" href={"https://twitter.com/intent/tweet?original_referer=" + window.location.href + "%2F&amp;source=tweetbutton&amp;text=" + Info.Title + "&amp;url=" + window.location.href + "%2F&amp;via="} onclick="javascript:window.open(this.href,'', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');return false;">
                                    <img src="/assets/img/tw-square.png" alt="Share on Twitter" />
                                </a>
                            </div>
                            <div className="col-md-12">
                                <a target="_blank" rel="noreferrer" href={CUSTOMER_CREATE_LADING_URL} className="btn btn-success w-100 white">{I18n.t("Banner.CreateLading")}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </MainLayout>
    )
}